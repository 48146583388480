.SliderContainer {
    display        : flex;
    flex-direction : column;
    justify-content: space-around;
    align-items    : center;
    width          : 100%;
    font-family: "Inter" !important;

    margin-top     : 40px
}

.slidable-element {
    transition: transform .5s ease-in-out
}

.fromRight {
    animation: .5s ease-out fromRight
}

@keyframes fromRight {
    from {
        transform: translate3d(100%, 0, 0)
    }
}

.toRight {
    animation: .5s ease-out toRight
}

@keyframes toRight {
    to {
        transform: translate3d(100%, 0, 0)
    }
}

#ImageViewer {
    width     : fit-content;
    height    : fit-content;
    max-height: 150px
}

.SliderHeader24 {
    font-weight: 700;
    font-size  : 24px;
    color      : #212121;
    margin     : 16px 0 0;
    text-align : center
}

.SliderDescription,
.SliderHeader20 {
    font-weight: 700;
    color      : #212121;
    margin     : 24px 0 0;
    text-align : center
}

.SliderHeader20 {
    font-size: 20px
}

.SliderDescription {
    font-size: 14px;
    width    : 90%
}

.SliderIndicator {
    width          : 100%;
    display        : flex;
    flex-direction : row;
    justify-content: center;
    align-items    : center;
    margin-top     : 48px;
    height         : 48px;
    margin-bottom  : 16px
}

.IndicatorContainer {
    width          : 48px;
    display        : flex;
    flex-direction : row;
    justify-content: space-between
}

.Indicator,
.IndicatorActive {
    width        : 6px;
    height       : 6px;
    border-radius: 50px;
    cursor       : pointer
}

.Indicator {
    background: #e7e7e7
}

.IndicatorActive {
    background: #1976d2
}